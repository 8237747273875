import { Controller } from "stimulus"

const initialShakeDelay = 3 * 1000
const shakeInterval = 10 * 1000

function validateAll(targets) {
  const results = targets.map(target => validatePresence(target))
  return results.filter(result => result).length >= results.length
}

function validatePresence(target) {
  if(!target.value) {
    target.classList.add('has-error')
    return false
  }
  return true
}

function hideTarget(target){
  target.classList.add('visually-hidden')
}

function showTarget(target) {
  target.classList.remove('visually-hidden')
}

function showError(target, message) {
  target.innerText = message
  showTarget(target)
}

function hideError(target) {
  hideTarget(target)
}

export default class extends Controller {
  static targets = [ "whoo", "orderForm", "card", "name", "email", "size", "street", "city", "state", "zip", "submit", "spinner", "error", "form", "paymentId" ]

  static values = { publishableKey: String, clientSecret: String }

  connect() {
    this.stripe = Stripe(this.publishableKeyValue);
    this.shakeTimeout = setTimeout(() => this.shake(), initialShakeDelay)
  }

  disconnect() {
    if(this.shakeTimeout) {
      clearTimeout(this.shakeTimeout)
    }
    if(this.clearShakeTimeout) {
      clearTimeout(this.clearShakeTimeout)
    }
  }

  closeWindow() {
    this.shakeTimeout = setTimeout(() => this.shake(), initialShakeDelay)
    this.orderFormTarget.style.display = "none"
  }

  show() {
    this.orderFormTarget.style.display = "flex"
    clearTimeout(this.shakeTimeout)

    if(!this.card) {
      this.setupStripeElements()
    }
  }

  shake() {
    this.whooTarget.classList.add('shake')
    this.clearShakeTimeout = setTimeout(() => this.whooTarget.classList.remove('shake'), 1000)

    this.shakeTimeout = setTimeout(() => this.shake(), shakeInterval)
  }

  submit(e) {
    e.preventDefault()
    // this.orderFormTarget.style.display = "none"
    if(validateAll([this.nameTarget, this.emailTarget, this.sizeTarget,
      this.streetTarget, this.cityTarget, this.stateTarget, this.zipTarget])) {
      this.handleStripePayment()
    }
    else {
      showError(this.errorTarget, "Please check the following fields:")
    }
  }

  setupStripeElements() {
    const elements = this.stripe.elements();
    const style = {
      base: {
        color: "#ccc",
        fontSize: "18px",
        fontFamily: "Roboto, sans-serif",
        '::placeholder': {
          color: '#555',
        },
      }
    };

    this.card = elements.create("card", { style: style });
    this.card.mount(this.cardTarget)
  }

  handleStripePayment() {
    hideError(this.errorTarget)
    showTarget(this.spinnerTarget)
    hideTarget(this.submitTarget)
    
    this.stripe
      .confirmCardPayment(this.clientSecretValue, {
        payment_method: {
          card: this.card,
          billing_details: {
            name: this.nameTarget.value,
            email: this.emailTarget.value,
            address: {
              line1: this.streetTarget.value,
              city: this.nameTarget.value,
              state: this.stateTarget.value,
              postal_code: this.zipTarget.value
            }

          }
        }
      })
      .then(result => {
        console.log({ result })
        if (result.error) {
          showError(this.errorTarget, result.error.message);
          hideTarget(this.spinnerTarget)
          showTarget(this.submitTarget)
        } else {
          this.paymentIdTarget.value = result.paymentIntent.id
          this.formTarget.submit()
        }
      });
  }

  checkField = (e) => {
    if(e.target.value) {
      e.target.classList.remove('has-error')
    }
  }

}
